<template>
    <div></div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { checkAuthEmail, getLoginUser } from '@/service/api/userApi';
import { subscribeSpaceList } from '@/service/api/profileApi';
export default defineComponent({
  name: 'EmailAuth',
  data () {
    return {
      toMail: this.$route.params.toMail,
      authCode: this.$route.params.authCode,
      aToken: this.$route.query.atoken,
      rToken: this.$route.query.rtoken
    };
  },
  methods: {
    async getEmailAuth () {
      try {
        const { data: authEmailData } = await checkAuthEmail(this.toMail, this.authCode);

        const { resultCd: authResultCd, result: authResult } = authEmailData;
        if (authResultCd === '0000' && authResult) {
          //
          this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
          this.$cookies.set('comeFrom', 'email');
          sessionStorage.removeItem('carCurationSpace');
          this.$store.commit('initData');
          if (this.aToken) {
            this.$cookies.set('aToken', this.aToken, '30d');
            this.$cookies.set('rToken', this.rToken, '30d');
            this.$cookies.set('email', this.toMail, '30d');

            this.$store.commit('setEmail', this.toMail);

            this.autoLogin();
          }
        } else {
          alert('만료된 인증코드거나 인증에 실패 했습니다.');
          this.$router.push({ name: 'Home' }).catch(() => {});
        }
      } catch (error) {
        alert('만료된 인증코드거나 인증에 실패 했습니다.');
        console.error('error : ', error);
        this.$router.push({ name: 'Home' }).catch(() => {});
      }
    },
    autoLogin () {
      const email = this.$cookies.get('email');
      getLoginUser(email).then((res) => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          const userId = result.userId;
          const email = result.email;
          const comeFrom = result.comeFrom;
          const userType = result.userType;
          const signupCouponAt = result.signupCouponAt;
          const division = result.division;
          // 쿠키 셋팅
          this.$cookies.set('userId', userId);
          this.$cookies.set('email', email);
          this.$cookies.set('comeFrom', comeFrom);
          this.$cookies.set('userType', userType);
          // store 셋팅
          this.$store.commit('setEmail', email);
          this.$store.commit('setSignupCouponAt', signupCouponAt);
          this.$store.commit('setDivision', division);

          subscribeSpaceList(userId)
            .then((res) => {
              if (res.data.result.length > 0) {
                this.$cookies.set('spaceId', res.data.result[0].spaceId);
              }
              this.$router
                .push({
                  name: 'PlayerView',
                  params: {
                    setIsLoading: true
                  }
                })
                .catch(() => {});
            })
            .catch(() => {
              this.$router
                .push({
                  name: 'PlayerView',
                  params: {
                    setIsLoading: true
                  }
                })
                .catch(() => {});
            });

          // this.$router.push({ name: 'PlayerView' }).catch(() => {});
        } else {
          alert('만료된 인증코드거나 인증에 실패 했습니다.');
          this.$router.push({ name: 'Home' }).catch(() => {});
        }
      });
    }
  },
  mounted () {
    this.getEmailAuth();
  }
});
</script>
